document.addEventListener("turbolinks:load", function () {

    var notification = document.querySelector('.notice');

    if (notification) {
        window.setTimeout(function () {
            notification.style.visibility = "hidden";
            notification.style.opacity = "0";
            notification.style.transition = "visibility 0s .5s, opacity .5s linear";
        }, 1500);
        window.setTimeout(function () {
            notification.style.display = "none";
        }, 2000);
    }

    var alert = document.querySelector('.alert');

    if (alert) {
        window.setTimeout(function () {
            alert.style.visibility = "hidden";
            alert.style.opacity = "0";
            alert.style.transition = "visibility 0s .5s, opacity .5s linear";
        }, 1500);
        window.setTimeout(function () {
            alert.style.display = "none";
        }, 2000);
    }
});

$(document).ajaxComplete(function () {
    var sent = document.querySelector('#sent');

    if (sent) {
        window.setTimeout(function () {
            sent.style.visibility = "hidden";
            sent.style.opacity = "0";
            sent.style.transition = "visibility 0s .5s, opacity .5s linear";
        }, 1500);
        window.setTimeout(function () {
            sent.style.display = "none";
        }, 2000);
    };
});